$font-size-base:              1rem !default; 

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.35 !default;
$h5-font-size:                $font-size-base * 1.2 !default;
$h6-font-size:                $font-size-base !default;



// What are these
$display1-size:               3rem !default;
$display2-size:               2.5rem !default;
$display3-size:               2rem !default;  // was 2.1875rem !default;
$display4-size:               1.6275rem !default;


$white:    #fff !default;
$gray-100: #f6f9fc !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #8898aa !default;   // Line footer color
$gray-700: #525f7f !default;   // Line p color
$gray-800: #32325d !default;   // Line heading color
$gray-900: #212529 !default;
$black:    #000 !default;


$light-bg: #F5F4F6; // CREATED for light backgrounds of boxes (login area) 
//// or lighter version: 848b80

$default:       #ABA799 !default;  // Strange Gold color . Where else is it used?
$primary:       #298800; // #298800 Ivy Green CMYK // rgb(41, 136, 0)
//$secondary:     #848b80 !default; // light green
//$success:       $green !default;
$info:          #60b93c !default;   // SLightly Brighter Green: 2d9d41
//$warning:       $orange !default;
//$danger:        $red !default;
$light:         $gray-500 !default;
//$dark:          $gray-900 !default;
//$darker:        darken($gray-900, 15%) !default;

// ****************** NEW BUTTON COLOR but can't assign to default
//B2A9A1

$border-width: 1px;
$border-color: $info; // green for borders. Why where?


$card-border-color: $gray-400;
$list-group-border-color: $gray-300;
$list-group-active-border-color: $gray-300;


// Override default annoying active shadow
// Some weird settings issues
// might not need now
$btn-active-box-shadow : none;
$btn-focus-box-shadow : none;
$btn-box-shadow:unset;
$btn-focus-width:0;

// $modal-header-padding: 1.1rem !default; //was 1.25
 $modal-inner-padding:  1.25rem !default;

//$font-family-base: 'Open Sans', sans-serif !default;
//$font-family-alt: 'Open Sans', sans-serif !default;

// COLOR PROFILE GENERATOR
// https://palx.jxnblk.com/
