/***********************************


.section is for argon sections with background (padding)

.content-section is for normal content (margins) either below a content section or by itself below a navigation


***********************************/






// * + * {
//     margin-top: 1.5em;
// }


// Maybe don't need any of these. Probably before argon
// body {
//     height: 100%;
//     background-color: white;

//     line-height: 1.6;
//     letter-spacing: 0.05rem;
//     word-spacing: 0.01rem;
//     font-weight: 300;
// }





// .modal-header, .modal-footer{
//     padding: 1.1rem;
// }



.user-list user-link {
    transition: all .15s ease;
}

.user-list user-link:hover {
    transform: translate(0%,-3%);

}


.lead-small {
    font-size: ($paragraph-font-size * 1.1);
    font-weight: $lead-font-weight;
    line-height: $paragraph-line-height;
}


 //FOR system layout pages so its not too small
.custom.system {

    .main .container {
        min-height: 70vh;
    }

    .section-footer .section {
        min-height: 250px;
    }

}

   
/***********************************

CUSTOM FORM SVG Backgrounds.  Missing from Argon for some reason !?!?

***********************************/


.custom-checkbox .custom-control-input~.custom-control-label {
  cursor: pointer;
}

// .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
//   border-color: #5e72e4;
// }

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled~.custom-control-label::before {
  border-color: #e9ecef;
}

.custom-checkbox .custom-control-input:disabled:checked::before {
  border-color: rgba(94, 114, 228, 0.5);
}

.custom-radio .custom-control-input~.custom-control-label {
  cursor: pointer;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
  //border-color: #5e72e4;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled~.custom-control-label::before {
  border-color: #e9ecef;
}

.custom-radio .custom-control-input:disabled:checked::before {
  border-color: rgba(94, 114, 228, 0.5);
}

   

// Put everything in the custom body tag to be more specific
.custom {


    font-family: "Hiragino Kaku Gothic Pro","Meiryo","MS Pgothic",helvetica,arial,sans-serif;

    /***********************************
    For tables to do wrapping
    ***********************************/
    td {
      white-space: normal !important; 
      word-wrap: break-word;  
    }
    table td, table th {
      //table-layout: fixed; causes all table cells to be same size. not good.
      padding: 0.75rem; 
    }



    @include media-breakpoint-only(md) {
        .modal-lg {
            width: 90%;
            max-width: 800px;
        }
    }

    .card-day-schedule {

        .list-group-item {
            
            min-height: 55px;

            font-weight: 500;
            padding: 0.5em 0.5em;

        }

        .img {
            width: 50px;
            height: auto;

        }


    }


    //  USING A PADDING TOP SYSTEM. 
    // Each section or element is responcible for seperating itself from the 
    // above element

    // .section is for argon sections with background (padding)
    // .content-section is for normal content (margins)

    // delete later
    .content-section {
        margin-top: 6rem;
        margin-bottom: 0rem;
    }

    // small top margin
    .content-section-s {

        margin-top: 4rem;
        margin-bottom: 0rem;

    }
    // medium top margin
    .content-section-m {
        margin-top: 6rem;
        margin-bottom: 0rem;

    }

    // class to use on columns to make content center
    // instead of d-flex justify-content-center
    .content-center {
        display: flex !important;
        justify-content: center !important;
    }

    .section-heading {
        h3, h4 {
            color: #298800;
        }
        
        justify-content: center !important;

        .divider
        {
            border-bottom: 3px solid #FFCD00;
        }
    }

    .section-sub-heading {
        h3, h4, h5 {
            color: black;
        }
        
        padding-top: 0.7rem;

        justify-content: center !important;

    }








    .content-block {

        margin-top: 4rem;
        justify-content: center !important;

    }



    
    

    .section-hero {

        // secstion heros have a background 
        // so they have padding top and bottom

        margin-top: 0rem;
        padding-top: 4rem;
        padding-bottom: 4rem;

        min-height: 50vh;
        max-height: 999999px;

        @include media-breakpoint-down(sm) { 
             min-height: 40vh;
        }


        @include media-breakpoint-down(md) {
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
        }

    }


    .lead {
        margin-top: 0rem;

    }




    .card-profile[data-image="img-raised"] {

        margin-bottom: 4rem;

        .card-header-image {
            max-height: none;
        }


    }



 // Removed from section hero
    // .page-header {

    //     //changed the section hero to handle height
    //     min-height: 0vh;
    //     max-height: 999999px;


    //     // makes it grow 100% in its parent flexbox (column)
    //     flex: 1;



    //     >.container,
    //     >.container-fluid {
    //       padding-top: 0vh;
    //       padding-bottom: 0vh;
    //       //margin-top: 12vh;
    //       //padding-bottom: 40px;
          
    //     }

    // }

    /********* FOOTER ************************/

    .section-footer {
        padding-bottom: 0rem;
    }

    .section-shaped .shape-style-1.shape-default {
        background: #298800;
    }



    /*********TOP HOME BANNER************************/
    .section-hero-home {

        background-image: url('/-images/jumbotron/girl-studying-lrg.jpg');
        background-size: cover;
        background-position: left bottom;
        background-repeat: no-repeat;


        .promo-img {

            width: 100%;
            max-width: 200px;
            height: auto;

            @include media-breakpoint-up(md) {
                max-width: 230px;
            }

            @include media-breakpoint-up(lg) {
                max-width: 240px;
            }

            @include media-breakpoint-up(xl) {
                max-width: 260px;
            }

        }

        @include media-breakpoint-down(lg) { 

            background-position: left -100px bottom;
            /// the second px value is offset for the first value
        }

        @include media-breakpoint-down(md) { 

            background-position: left -50px center;

            /// width height
            background-size: auto 100%;
            
        }


        @include media-breakpoint-up(xl) { 
            
        }
        
        //background: #C9CBC8; //Grey with a little green
    }





    .card-box {

        background-color: $light-bg; 
        padding: 1rem; // equal to pt-3 
        border: 1px solid darken($light-bg, 10%) !important;
        margin-bottom: 1rem;
        
        &.student-profile {

            min-height: 200px;

            @include media-breakpoint-down(md) {
                margin-top: 1.5rem;
            }


        }
       



    }

    // Student Profile
    .card-profile.list {
        max-width: 280px;
        z-index: 1;

    }
   
    .card-profile .card-header {
        height: 100px;
        margin-bottom: 70px;
        background-image: url('/img/ill/inn.svg');
        background-repeat: repeat;
        background-size: auto 300%; 

    }

    .card-profile .card-avatar {

        margin: 20px auto 0;
    }
    // Student Profile



    @include media-breakpoint-down(md) {
        .navbar-nav {
            .nav-link {
                color: theme-color("primary") !important;
            }
        }
    }


    .navbar-expand-lg .navbar-nav .nav-link {

        padding-right: 0.8rem;
        padding-left: 0.8rem;

        @include media-breakpoint-up(xl) {
            padding-right: 0.9rem;
            padding-left: 0.9rem;
        }
    }


   .navbar .dropdown-menu {

        padding: 0.4rem 0rem;
    }


    .navbar .dropdown-menu .dropdown-item {

        padding: 0.8rem;
        padding-left: 1.4rem;

    }

    .navbar .dropdown .dropdown-item:hover, .dropup .dropdown-item:hover {
        transform: scale(1.03);
    }

    .navbar .dropdown .dropdown-item {
        transition: all 0.1s ease; // make faster
    }


    // .navbar .btn:hover {
    //     transform: translateY(-1px);
    // }

    .navbar .btn {
        background-color: white; 
        border: 2px solid $primary; 
        color: $primary;
        font-weight: 300;
    }

    .nav-link {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }


    .navbar-brand {

        img {
            height: 80px; 
        }

    }

    // Fix navigation text aligning with button
    @include media-breakpoint-up(lg) {
        .navbar-nav {
            align-items: center;
        }

        #navbar-mobile {
            display: none;
        }


    }




    // This is the circle shape section (position absolute)
    // .section-shaped .shape-style-1.shape-primary {
    // }


    /********* FORM ************************/

    .form-check {
        padding-left: 0rem;
    }


    .form-control {
        // height of all for form elements. changed rem
        height: calc(1.5em + 1rem + 2px);

        padding: 0.3rem 0.7rem;

    }


    // FIX safari (and other) dropdowns. Arrow color can be changed in fill
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%238C98F2'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
      background-size: 10px;
      background-position: calc(100% - 8px) center;
      background-repeat: no-repeat;
      //background-color: ; can set
      //min-width: 3.5rem;
    }


    // English Form Single Line Breakpoint changes
    @media screen and (max-width: 767.9px) { 

        .en-form {
            & .form-control-range {
                margin-top: 1rem;
            }

            & .my-form-check-group {
                margin-top: 1rem;
            }
        }
    }

    // Japanese Form Single Line Breakpoint changes
    @media screen and (max-width: 575.9px) { 

        .ja-form {
            & .form-control-range {
                margin-top: 0.7rem;
            }

            & .my-form-check-group {
                margin-top: 0.7rem;
            }
        }
    }


    .card-header .close {
        opacity: 0.9;
    }

    .card-header .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
        opacity: 1;
    }

    .card-header .close > span:not(.sr-only) {

        font-size: 2rem;
        color: white;
    }




} // Finish Custom Overrides of Argon





 // * The following styles are auto-applied to elements with
 // * transition="modal" when their visibility is toggled
 // * by Vue.js.
 // *
 // * You can easily play with the modal transition by editing
 // * these styles.


// THIS IS THE TRICK TO MAKE ABSOLUTE ELEMENTS GO TO THE TOP
.wrapper {
  display: flex;
  flex-direction: column;
  >* {
    transition: all 0.5s;
    width:100%;
  };
}



// .modal-body {
//      transition: all 0.5s;
// }

    
// SAME AS ABOVE BUT SEPERATE FOR MODAL MAYBE NOT NEEDED
.modal-body {

  transition: all 0.2s;
    width:100%;
    transition-timing-function: linear;
}



// Maybe change to rem
.goright-enter,
.goright-leave-active {
  opacity: 0;
  transform: translateX(200px);
}

.goleft-enter,
.goleft-leave-active {
  opacity: 0;
  transform: translateX(-200px);
}


.goleft-leave-active, 
.goright-leave-active {
    position: absolute;
}



.fade-enter-active{
  transition-duration: 0.8s;
  transition-property: opacity;
}

.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
 
}

// .fade-leave-active {
//     display: none;
// }


